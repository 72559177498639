export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    location: {
      type: String,
      default: "inline-start",
    },
    title: {
      type: [String, Boolean],
      default: false,
    },
    offset: {
      type: String,
      default: "0px",
    },
    shader: {
      type: Boolean,
      default: true,
    },
    dropShadow: {
      type: Boolean,
      default: true,
    },
    showCloseIcon: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {};
  },
  computed: {
    opened: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    calculatedOffset() {
      return this.offset;
    },
    renderShader() {
      // a little complicated.
      // if either is false - than false
      return !(!this.dropShadow || !this.shader);
    },
    hasHeaderContent() {
      return (
        this.title || this.$slots["header-content"] || this.$slots["title"]
      );
    },
  },
  emits: ["update:modelValue"],
  methods: {
    close() {
      this.opened = false;
    },
  },
  beforeUnmount() {},
};
