<template>
  <teleport to="body">
    <div
      v-if="renderShader"
      class="drawer-shader fixed-cover"
      :class="{ active: opened }"
      @click="opened = false"></div>
    <div
      v-bind="$attrs"
      class="drawer"
      :class="{
        active: opened,
        ['drawer--' + location]: true,
      }">
      <slot name="header" v-bind="{ drawer: this }">
        <div
          v-if="hasHeaderContent"
          class="drawer-header flex-between flex-middle margin-xl-bottom"
          :class="{
            'flex-row-reverse': location === 'inline-end',
          }">
          <slot name="header-content">
            <h3
              v-if="title"
              class="margin-remove text-center"
              style="flex: 100%">
              {{ translateTitleCase(title) }}
            </h3>
          </slot>
          <icon
            v-if="showCloseIcon"
            icon="x"
            class="cursor-pointer drawer__close"
            :size="1.75"
            @click="opened = false" />
        </div>
      </slot>
      <div
        v-if="!hasHeaderContent && showCloseIcon"
        class="mini-header"
        :class="{
          'flex-row': location === 'inline-end',
          'flex-row-reverse': location !== 'inline-end',
        }">
        <icon
          icon="x"
          class="cursor-pointer drawer__close drawer__close--absolute"
          :size="1.75"
          @click="opened = false" />
      </div>

      <slot name="drawer-content">
        <div class="drawer-content">
          <slot></slot>
        </div>
      </slot>
    </div>
  </teleport>
</template>

<script>
import drawer from "@/client/extensions/mixins/baseComponents/etc/drawer";

export default {
  mixins: [drawer],
};
</script>

<style lang="scss" scoped>
.drawer {
  --drawer-offset: v-bind(calculatedOffset);
}
</style>
